import * as React from 'react';
import { Form, IFields, required, isSameAsPassword } from './Form';
import { Field } from './Field';

interface Props {
  onConfirmPassword: (password: string) => void;
  emailExists?: boolean;
  passwordFormMessage?: string;
  passwordFormButtonText?: string;
}

export const SetPasswordForm = (props: Props) => {
  const setPasswordFields: IFields = {
    password: {
      id: 'password',
      label: 'Password',
      validation: { rule: required },
    },
    confirmpassword: {
      id: 'confirmpassword',
      label: 'Confirm password',
      validation: { rule: isSameAsPassword },
    },
  };
  const loginFields: IFields = {
    password: {
      id: 'password',
      label: 'Password',
      validation: { rule: required },
    },
  };
  return (
    <header className="password-header">
      <input type="text" className="input-text" hidden></input>
      <img className="userprofile-icon" height="26" width="26" alt="" src="/userprofile.png" />
      <label className="password-message">
        {props.passwordFormMessage ? props.passwordFormMessage : 'Set your new password.'}
      </label>
      <fieldset className="fieldset">
        <Form
          fields={props.emailExists ? loginFields : setPasswordFields}
          passwordFormButtonText={props.passwordFormButtonText}
          callback={props.onConfirmPassword}
          render={() => (
            <React.Fragment>
              {props.emailExists ? (
                <div className="fields">
                  <Field {...loginFields.password} />
                </div>
              ) : (
                <div className="fields">
                  <Field {...setPasswordFields.password} />
                  <Field {...setPasswordFields.confirmpassword} />
                </div>
              )}
            </React.Fragment>
          )}
        />
      </fieldset>
    </header>
  );
};
