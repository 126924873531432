import React from 'react';
import { Auth } from 'aws-amplify';
import Amplify from '@aws-amplify/core';
import queryString from 'query-string';
import './App.css';
import { SetPasswordForm } from './SetPasswordForm';
import { ConfirmSignUp } from './ConfirmSignUp';

Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_g6Wn8ytjH',
    userPoolWebClientId: '74oon2v6eo9ivccc7d0qrarr3p',
  },
  Analytics: {
    disabled: true,
  },
});

const amplifySignIn = async (username: string, password: string) => {
  try {
    await Auth.signIn(username, password);
    return true;
  } catch (error) {
    console.log('could not login', error);
  }
  return false;
};

// const BASEURL = 'http://localhost:3000';
const BASEURL = 'https://user-be.stellarworkouts.com';

export default class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    // because S3 redirect doesn't keep query parameters but adds them to the hash part
    // we use this to work for both "normal" query parameters and when s3 has redirected
    // away from them
    const qsPart = window.location.href.substr(window.location.href.indexOf('?'));
    const qsObject = queryString.parse(qsPart);
    const isGympassMembership = window.location.href.includes('/gympassmembership');

    const state: any = {
      setPasswordSuccess: false,
      createGympassMembershipSuccess: false,
      validGympassEmail: false,
      confirmedSignUp: false,
      emailExisted: false,
      id: qsObject.id,
      code: qsObject.code,
      isSetPassword: window.location.href.includes('/passwordreset'),
      isVerifyEmail: window.location.href.includes('/verifyemail'),
      isConfirmUser: window.location.href.includes('/confirmuser'),
      isGympassMembership,
    };

    if (isGympassMembership) {
      state.encryptedEmail = qsObject.email;
      state.encryptedFirstName = qsObject.firstname;
      state.encryptedLastName = qsObject.lastname;
      state.gympassWrongPassword = false;
    }

    this.state = state;
  }

  componentDidMount = () => {
    if (this.state.isConfirmUser) {
      this.onConfirmSignUp();
    } else if (this.state.isGympassMembership) {
      this.onConfirmValidGympassUser();
    }
  };

  onConfirmSignUp = async () => {
    const { id, code } = this.state;
    if (id !== '' && code !== '') {
      try {
        await Auth.confirmSignUp(id, code);
        this.setState({ confirmedSignUp: true });
      } catch (err) {
        alert(err.message);
      }
    }
  };

  onConfirmValidGympassUser = async () => {
    // make sure we are signed out
    Auth.signOut();

    const { encryptedEmail, encryptedFirstName, encryptedLastName } = this.state;
    if (encryptedEmail !== '') {
      try {
        const body = { email: encryptedEmail, firstName: encryptedFirstName, lastName: encryptedLastName };

        const response = await fetch(`${BASEURL}/gympass/decrypt`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });

        const { email, firstName, lastName } = await response.json();

        this.setState({
          email,
          firstName,
          lastName,
          validGympassEmail: true,
        });
      } catch (err) {
        alert(err.message);
      }
    }
  };

  onConfirmPasswordInternal = async (successState: any, username: string, code: string, password: string) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      this.setState(successState);
    } catch (err) {
      alert(err.message);
    }
  };

  onGympassSignedIn = async () => {
    const { encryptedEmail } = this.state;

    try {
      const jwtToken = await (await Auth.currentSession()).getAccessToken().getJwtToken();

      await fetch(`${BASEURL}/gympass/addMembership`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          encryptedEmail,
        }),
      });
      await this.setState({ createGympassMembershipSuccess: true });
    } catch (error) {}
  };

  onConfirmGympassPassword = async (password: string) => {
    this.setState({ gympassWrongPassword: false });

    const { emailExisted, email, firstName } = this.state;

    const lowerEmail = email.toLowerCase();

    if (emailExisted) {
      const signedIn = await amplifySignIn(lowerEmail, password);
      if (!signedIn) {
        this.setState({ gympassWrongPassword: true });
      }
    } else {
      try {
        const authRes = await Auth.signUp({
          username: lowerEmail,
          password,
          attributes: {
            email: lowerEmail,
            given_name: firstName,
          },
        });

        if (authRes.userConfirmed) {
          await amplifySignIn(lowerEmail, password);
        }
      } catch (error) {
        if (error.code === 'UsernameExistsException') {
          // try to login with the password

          const loggedIn = await amplifySignIn(lowerEmail, password);
          if (!loggedIn) {
            this.setState({ emailExisted: true });
          }
        }
      }
    }

    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      this.onGympassSignedIn();
    }
  };

  onConfirmPassword = async (password: string) => {
    const urlSplit = window.location.href.split('passwordreset?');

    if (urlSplit.length === 2) {
      const splitted = urlSplit[1].split('&');
      if (splitted.length === 2) {
        const username = splitted[0].replace('id=', '');
        const code = splitted[1].replace('code=', '');

        if (username !== '' && code !== '' && password !== '') {
          this.onConfirmPasswordInternal({ setPasswordSuccess: true }, username, code, password);
        }
      }
    }
  };

  render() {
    return (
      <div className="app">
        <div className="top-bar">
          <img className="top-logo" alt="" src="/logo.png" />
        </div>
        {this.state.isSetPassword ? ( //
          this.state.setPasswordSuccess ? (
            <header className="password-header">
              <label className="password-message">Your password was successfully changed.</label>
            </header>
          ) : (
            <SetPasswordForm
              onConfirmPassword={(password) => {
                this.onConfirmPassword(password);
              }}
            />
          )
        ) : this.state.isConfirmUser ? (
          <header className="app-header">
            <ConfirmSignUp signUpConfirmed={this.state.confirmedSignUp} />
          </header>
        ) : this.state.isVerifyEmail ? (
          <header className="verification-header">
            <a
              href={`stellarworkouts://?action=verifyemail&username=${this.state.id}&code=${this.state.code}`}
              className="open-app-button"
            >
              Open Stellar Workouts App
            </a>
          </header>
        ) : this.state.isGympassMembership ? (
          this.state.validGympassEmail ? (
            this.state.createGympassMembershipSuccess ? (
              <header className="password-header">
                <label className="password-message">
                  Your gympass account is now integrated, please download Stellar app in appstore and sign in with your
                  gympass email.
                </label>
                <a href="https://apps.apple.com/us/app/stellar-home-workouts/id1461086891">Stellar App</a>
              </header>
            ) : (
              <>
                <SetPasswordForm
                  emailExists={this.state.emailExisted}
                  onConfirmPassword={(password) => {
                    this.onConfirmGympassPassword(password);
                  }}
                  passwordFormMessage={
                    this.state.emailExisted
                      ? 'You already have a Stellar account, sign in to connect it to Gympass!'
                      : 'Set your password to finish registration with Gympass'
                  }
                  passwordFormButtonText={this.state.emailExisted ? 'Sign in' : 'Set new password'}
                />
              </>
            )
          ) : (
            <header className="password-header">
              <label className="password-message">
                This link is unvalid, expired or this gympass user already exists in our system. Try to download Stellar
                app in appstore and login to your user email, if you have problems with your password, choose forgot
                password option in the app{' '}
              </label>
              <a href="https://apps.apple.com/us/app/stellar-home-workouts/id1461086891">Stellar App</a>
            </header>
          )
        ) : (
          <header className="password-header">
            <label className="password-message">Welcome to Stellar Workouts</label>
          </header>
        )}
        <div className="bottom-bar">
          <img className="bottom-icon" alt="" src="/mini-logo.png" />
        </div>
      </div>
    );
  }
}
